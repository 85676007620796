.pdfinput{
  width: 500px;
  margin-top: 20px;
}

.App_{
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.outputtext{
  width: 500px;
  padding: 20px;
  height: 500px
}

.pdfsummarypage{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header_text
{
  text-align: center;
}

.pdfsummarytitle{
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width:580px) {
  .outputtext{
    width: 280px;
    padding: 20px;
    height: 500px
  }

  .pdfinput{
    width: 280px;
    margin-top: 20px;
  }
  
}